html {
  body {

      .main {
        .single {
          background: $white;
          padding-top: 1rem;
          .singlerightside {
            .h2 {
              color: $basic-blue;
              font-size: 21px;
            }
            .single-description {
              margin-top: 24px;
              font-size: 14px;
              .articlenumber {
                b {
                  color: $black;
                }
              }
            }
            .graduated-prices-table {
              font-size: 12px;
            }

            b {
              font-size: 14px;
            }
            .item-bundle {
              .text-primary {
                color: #6c757d !important;
              }
            }
            .block-a {
              span {
                font-size: 14px;
              }
            }
            .basket-block {
              .add-to-backet-a {
                float: left;
                width: 80%;
              }
              .add-to-wish-list-block {
                float: left;
                width: 49px;
                border: 1px solid #dcdbd8;
                padding: 11px 0 7px;
                margin-top: 14px;
                border-radius: 2px;
                .add-to-wish-list {
                  span {
                    display: none;
                  }
                  i {
                    font-size: 18px;
                    margin-right: 0;
                  }
                }
                &:hover {
                  border: 1px solid #d9534f !important;
                  transition: 0.7s;
                  i {
                    color: #d9534f !important;
                    transition: 0.7s;
                  }
                }
              }
            }
          }
          .info-table {
            .nav-link {
              padding: .75rem 1rem;
              border: none;
            }
            .tab-content {
              border: 1px solid #ddd;
              border-top: none;
              padding: 15px;
              //width: 66vw;
              //max-width: 800px;
              .tab-pane {
                &.active {
                  border-bottom: 1px solid transparent !important;
                  border-radius: 3px 3px 0 0;
                }
              }
            }
            .nav-tabs {
              .nav-item {
                .nav-link {
                  &.active {
                    border-bottom: 1px solid transparent !important;
                    border-left: 1px solid #ddd !important;
                    border-right: 1px solid #ddd !important;
                    border-top: 1px solid #ddd !important;
                    border-radius: 3px 3px 0 0;
                    background: $white;
                  }
                }
              }
            }
          }
          .owl-carousel {
            .owl-height {
              height: auto !important;
              .owl-item {
                img {
                  height: auto !important;
                }
              }
            }
            .carousel-control {
              &.right {
                .owl-single-item-control {
                  background: $black;
                  color: $white;
                  padding: 4px 6px 4px 7px;
                  opacity: .6;
                  font-size: 2rem;
                }
              }
              &.left {
                .owl-single-item-control {
                  background: $black;
                  color: $white;
                  font-size: 2rem;
                  padding: 4px 7px 4px 6px;
                  opacity: .6;
                }
              }
            }
            .owl-dots {
              display: none;
            }
          }

          .crossselling-block {
            .cmp-product-thumb {
              .thumb-inner {
                .thumb-content {
                  .thumb-title {
                    color: $basic-blue;
                    font-size: 14px;
                    min-height: 85px;
                    line-height: 20px;
                  }
                  .thumb-meta {
                    .prices {
                      display: block;

                      .price {
                        color: #333;
                        text-align: center;
                        float: none;
                      }
                    }
                  }
                  .category-unit-price {
                    display: none;
                  }
                }
              }
              .category-list-view-port {
                display: block;
                .col-12 {
                  padding: 0;
                  /*.btn {
                    i {
                      display: none;
                    }
                  }*/
                }
              }
            }
          }
        }
      }

  }
}
