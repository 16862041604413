@media (min-width: 1200px) {
  html {
    .main-navbar-collapsable {
      display: block !important;
    }
  }
}

@media (max-width: 1199px) {
  html {
    .main-navbar-collapsable {
      display: none !important;
    }

    .single {
      .singlerightside {
      }
    }
  }
}


@media (min-width: 992px) and (max-width: 1024px) {
  html {
      .main {
        .crossselling-block {
          .add-to-basket-container {
            .quantity-input-container {
              .qty-box {
                .qty-input {
                  width: 37px;
                }
              }
            }
            .btn {
              padding: 0.2rem;
            }
          }
        }
        .widget {
          &.crossselling-widget {
            .add-to-basket-container {
              .qty-box {
                .qty-input {
                  width: 35px;
                }
              }
              .btn {
                padding: .2rem;
              }
            }
          }
        }
      }

  }
}


@media (max-width: 991px) {

  html {
    body {
      #vue-app {

          .top-bar {
            .showcase-a {
              text-align: center;
            }
            .top-navi {
              .controls-list {
                div#login-change {
                  span {
                    display: none;
                  }
                }
              }
            }

            .cmp-search-box {
              .search-box-inner {
                max-width: 500px;
                margin: 10px auto 25px auto;
              }
            }
            .container-max {
              padding-right: 0;
              padding-left: 0;
            }
          }

          #CfourListingSwitch {
            margin-top: 5px;
            margin-bottom: 6px;
            margin-left: -9px;
          }


        .main {
          .single {
            .singlerightside {
              .price {
                float: none;
              }
            }
            .single-basket-button-block {
              .qty-box {
                .qty-input {

                }
              }
            }
            .info-table {
              .tab-content {
                //width: 96vw;
              }
            }
            //.crossselling-block {
            //  .col-md-3 {
            //    flex: 0 0 33%;
            //    max-width: 33%;
            //  }
            //}
          }

           .widget-item-list {
             .add-to-basket-container {
                 .btn {
                   font-size: 13px;
                 }
               }
             }

          .categoriegrid {
           .add-to-basket-container {
              .btn {
                font-size: 13px;
              }
            }
          }
        }
      }
    }
  }
}



@media (min-width: 768px) and (max-width: 991px) {
  html {
    body {
      #vue-app {
          .main {
            .crossselling-block, .crossselling-widget {
              .col-md-3 {
                flex: 0 0 50%;
                max-width: 50%;
              }
            }
          }
        }
    }
  }
}


@media (min-width: 768px) and (max-width: 819px) {
 html {

     .main {
       .crossselling-block {
         .btn {
           padding: .2rem 1rem;
          }
        }
       .CfourListingButtons {
           .qty-box {
             .qty-input {
               width: 40px;
             }
           }
          .btn {
            padding: .5rem;
            i {
              display: none;
            }
          }
        }
      }

  }
}

@media (max-width: 768px) and (max-width: 801px) {
  html {
    body {

        .main {
          .widget {
            &.crossselling-widget {
              .add-to-basket-container {
                .qty-box {
                  .qty-input {
                    width: 35px;
                  }
                }
                .btn {
                  padding: .5rem .5rem;
                }
              }
            }
          }
          .single {
            .crossselling-block {
              .add-to-basket-container {
                .qty-box {
                  .qty-input {
                    width: 35px;
                  }
                }
                .btn {
                  padding: .5rem .5rem;
                }
              }
            }
          }
        }

    }
  }
}

@media (max-width: 767px) {
  html {
    body {
      #vue-app {

          .top-bar {
            .showcase-a {
              .brand-wrapper {
                .navbar-brand {
                  img {
                    max-height: 4.5rem;
                  }
                }
              }
            }
          }
          .main {
            .cmp-product-thumb {
              .thumb-image {
                width: 100%;
                margin-bottom: 15px;
              }
            }
            h2, .h2 {
              font-size: 1.4rem;
            }
            .widget {
              &.widget-image-carousel {
                .carousel-control {
                  &.carousel-control-prev {
                      .fa {
                        font-size: 1.5rem;
                        padding: 7px 7px 7px 9px;
                      }
                  }
                  &.carousel-control-next {
                      .fa {
                        font-size: 1.5rem;
                        padding: 7px 9px 7px 7px;
                      }
                  }
                }
                .carousel-indicators {
                  li {
                    border-color: #ccc;
                    &.active {
                      background-color: #ccc;
                    }
                  }
                }
              }
            }
            .single {
              .singlerightside {
                margin-top: 25px;
              }
            }
          }

      }
    }
  }
}

@media (max-width: 576px) {
  html {
    body {

        .main {
          .single {
            .info-table {
             .tab-content {
               //width: 92vw !important;
               //max-width: 100% !important;
               .single-description-tab {
                 width: 100%;
               }
             }
              .nav-tabs {
                display: block;
                .nav-item {
                  .nav-link {
                    border: 1px solid #ddd;
                    border-radius: 0;
                    background: #fff;
                    &.active {
                      background: $basic-blue !important;
                      color: $white;
                    }
                  }
                }
              }
            }
            .crossselling-block {
              .cmp-product-thumb {
                .thumb-content {
                  width: 100%;
                  .thumb-title {
                    font-weight: 100;
                  }
                }
              }
            }
            .add-to-backet-a {
              .category-list-view-port {
                i {
                  &.fa-shopping-cart {
                       display: none;
                  }
                }
              }
            }
          }
        }
        .mobile-navigation {
          ul {
            &.mainmenu {
              li {
                a {
                  font-size: 14px;
                }
              }
            }
          }
        }

    }
  }
}

@media (max-width: 575px) {
  html {
    body {

        .top-bar {
          h4 {
            font-size: 16px;
          }
        }

        .main {
          .widget-item-list {
            .cmp-product-thumb {
              .basket-button-a {
                .add-to-basket-lg-container > i {
                  display: block;
                }
              }
              .thumb-content {
                width: 100%;
                .thumb-title {
                  min-height: auto;
                  font-weight: 100;
                }
              }
            }
          }

          .categoriegrid {
            #CfourListingSwitch {
              margin-top: -35px !important;
            }

            .pagination {
              padding-right: 0;
            }
            .product-list {
              .cmp-product-thumb {
                .thumb-content {
                  width: 100%;
                }
              }
            }
            .thumb-content {
              width: 100%;
              .thumb-title {
                min-height: auto;
              }
            }
          }
          .single {
            .nav {
              //flex-wrap: nowrap;
              display: block;
              max-width: 100%;
            }
            //.info-table {
            //  .tab-content {
            //    width: 93vw !important;
            //  }
            //}
            .crossselling-block {
              .cmp-product-thumb {
                .thumb-content {
                  width: 100%;
                  padding: 0;
                  .thumb-title {
                    font-weight: 100;
                  }
                }
              }
            }
          }
        }

    }
  }
}


@media (max-width: 479px) {
  html {
    body {
      #vue-app {

          .top-bar {
            .showcase-a {
              .brand-wrapper {
                .navbar-brand {
                  img {
                    max-height: 3rem;
                  }
                }
              }
            }
          }
          .main {
            .cmp-product-thumb {
              .thumb-image {
                width: 100%;
                margin-bottom: 15px;
              }
            }
            h2, .h2, h3, .h3, h4, .h4 {
              font-size: 1.2rem;
            }
            .widget {
              &.widget-image-carousel {
                .carousel-control {
                  &.carousel-control-prev {
                    .fa {
                      font-size: 1.25rem;
                      padding: 7px 6px 5px 12px;
                    }
                  }
                  &.carousel-control-next {
                    .fa {
                      font-size: 1.25rem;
                      padding: 7px 12px 5px 5px;
                    }
                  }
                }
              }
            }
            .CfourListingButtons {
              width: 100%;
            }
          }

      }
    }
  }
}


@media (max-width: 320px) {
  html {
    body {

        .top-bar {
          .showcase-a {
            .brand-wrapper {
              .navbar-brand {
                img {
                  max-height: 2.4rem !important;
                }
              }
            }
          }

          h4 {
            font-size: 14px;
          }
        }

        .main {
          .basket {
            padding-right: 0;
            padding-left: 0;

            .bg-white {
              padding: 0;
            }
          }
          .single {
            .add-to-backet-a {
              .btn {
                i {
                  display: none;
                }
              }
            }

            //.info-table {
            //  .tab-content {
            //    width: 91vw !important;
            //  }
            //}

            .crossselling-block {
              .cmp-product-thumb {
                .thumb-content {
                  padding: 0;
                  width: 100%;
                }
              }
            }

            .basket-block {
              .add-to-basket-container {
                .quantity-input-container {
                  .qty-box {
                    .qty-input {
                      width: 37px;
                    }
                  }
                }

                .btn {
                  padding: 0.2rem;
                }
              }
            }
          }
        }

      /*.wrapper-right {
        .basket-preview {
          .basket-preview-content {
            .cmp-totals {
              #ppButton, .amzPayButton {
                width: 100%;
              }
            }
          }
        }
      }*/
    }
  }
}
