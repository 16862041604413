html {
  .blog-wrapper {
    .blog-header {
      background: $white;
      border-bottom: none;
      padding: 30px 0 0;
       .blog-breadcrumbs > nav ul.breadcrumb {
         padding-left: 0 !important;
       }
    }
  }

  .top-bar .controls-list.blog-entrypoint {
    //order: 0;
    margin-left: 0;
  }
}

