.widget-image-carousel.widget-proportional {
  padding-bottom: 42.1052631579%;
}
.widget.widget-image-box.widget-image-box-no-caption.widget-primary.widget-proportional {
  padding-bottom: 0;
}
.widget.widget-image-box.widget-image-box-no-caption.widget-primary.widget-proportional img {
  position: static;
  transform: none;
}

.widget {

  &.widget-text {
    background: $white;
    .widget-inner {
      .headline-a {
        margin-bottom: -70px;
        background: $white;
        padding-bottom: 49px;
        padding-left: 21px;
        margin-left: -15px;
        margin-right: -15px;
      }
    }
  }

  &.widget-grid {
    background: $white;
    padding: 30px 0;
    &.widget-container {
      margin-top: -65px;
    }
  }

  &.widget-image-carousel {
    .carousel-control {
      &.carousel-control-next, &.carousel-control-prev {
        width: 3%;

        .fa {
          font-size: 2rem;
          background: $black;
          color: $white !important;
          padding: 7px 10px 6px;
        }

      }
    }

    .carousel-indicators {
      li {
        border-color: #ccc;
        &.active {
          background-color: #ccc;
        }
      }
    }
  }

  &.widget-item-list {
    .widget-caption {
     display: none;
    }
    .cmp-product-thumb {
      border: 1px solid #ddd;
      .thumb-content {
        .thumb-title {
          color: $basic-blue;
          font-size: 14px;
          min-height: 85px;
          line-height: 20px;
        }
        .thumb-meta {
          .prices {
            flex-direction: column-reverse;
            .price {
              color: #333;
              text-align: center;
            }
          }
        }
        .basket-button-a {
          .add-to-backet-a {
            .category-list-view-port {
              display: block;
              .col-12 {
                padding: 0;
                /*.btn {
                  i {
                    display: none;
                  }
                }*/
              }
            }
          }
        }
        .category-unit-price {
          display: none;
        }
        .vat {
          display: block;
          text-align: center;
          margin-top: 2px;
        }
      }
    }
    .list-item-carousel {
      .carousel-control {
        &.right {
          .owl-single-item-control {
            background: $black;
            color: $white;
            padding: 4px 6px 4px 7px;
            opacity: .6;
            font-size: 2rem;
          }
        }
        &.left {
          .owl-single-item-control {
            background: $black;
            color: $white;
            font-size: 2rem;
            padding: 4px 7px 4px 6px;
            opacity: .6;
          }
        }
        &:hover {
          i {
            color: $basic-blue;
          }
        }
      }
      .owl-dots {
        display: none;
      }
    }
  }
}

div#CfourNewsletterRegistration {
  margin-right: 15px;
  margin-left: 15px;
  border-top: 1px solid #eee;
  padding-top: 20px;
  .discount-text {
    span {
      font-size: 12px;
    }
  }
  .inputs {
    display: flex;
    & > .btn-default {
      background: $basic-blue;
      color:$white;
      flex: 0 0 25%;
      height: 33px;
      padding: 7px;
      margin-left: 25px;
    }
    .form-group {
      margin-top: 10px;
      flex: 0 0 115%;
      max-width: 115%;
    }
  }
}


@media (max-width: 767px) {
  div#CfourNewsletterRegistration {
    .inputs {
      display: block;
      & > .row {
        & > .col-12 {
          flex: 0 0 100%;
          max-width: 100%;
          margin-bottom: 10px;
          & > .form-control {
            height: calc(2.75rem + 2px);
          }
        }
      }
      & > .btn-default {
        padding: 0 9px;
        margin-left: 0;
        height: 40px;
      }
    }
  }
  .widget.widget-grid.widget-container {
    margin-top: -70px;
    margin-bottom: 15px;
    padding-bottom: 5px;
  }
}

.widget-newsletter {
  max-width: 1200px;
  margin: auto;
  padding: 15px;
  margin-bottom: 2rem !important;
  @media (max-width: 767px) {
    padding-bottom: 45px;
  }
  @media (max-width: 575px) {
    padding: 15px 15px 35px;
  }

  h3 {
    margin: 15px 0;
  }

  .input-unit input[type], .input-unit textarea, .input-unit input:not([type="checkbox"]), .input-unit input:not([type="radio"]), .input-unit .input-unit-preview {
    padding: .6rem .8rem;
  }

  .form-check {
    @media (max-width: 767px) {
      margin-top: 5px;
      margin-bottom: 20px;
    }

    .form-check-label {
      //font-size: 12px;
      //font-family: "Open Sans", serif;

      a {
        color: #4D4F4D;
        //text-decoration: underline;
        font-weight: 700;

        &:hover {
          //color: $c4-dark-grey-font !important;
        }
        span {
          color: #4D4F4D !important;
        }
      }
    }
    &.error span.text-primary {
      color: #fff !important;
    }
  }

  .input-group-btn {
    button {
      //font-family: "Open Sans", serif;
      background: $basic-blue !important;
      border-color: $basic-blue !important;
    }

    .input-unit {
      border: 1px solid rgba(0, 0, 0, 0.15);

      input::placeholder {
        //color: $c4-dark-grey-font !important;
      }

      //
      //&:-ms-input-placeholder {
      //  color: $c4-dark-grey-font !important;
      //}
      //
      //&::-ms-input-placeholder {
      //  color: $c4-dark-grey-font !important;
      //}

      input[type="text"]::placeholder {
        //color: $c4-dark-grey-font !important;
      }
    }
  }

  .button-to-subscribe {
    @media (max-width: 767px) {
      position: absolute;
      bottom: -30px;
      width: 100%;
      padding: 0;
      margin: 0;
      right: 0;
      z-index: 999;
    }
  }
}

.newsletter-block {
  margin-bottom: -3rem;
  margin-top: -4rem;
  border-top: 1px solid #eee;
  padding-top: 1.5rem;
}
