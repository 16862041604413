html {
  body {
    #vue-app {
      background: url("https://www.wasserfilter-berlin.de/layout/callisto/img/hinter.jpg") no-repeat fixed center center / cover transparent;
      margin-top: 0 !important;


        .top-bar {
          background: $white;

          .showcase-a {
            margin-top: 10px;

            .logo-bar {
              li {
                list-style-type: none;
                display: inline-flex;
                margin-right: 7px;
              }
            }

            .brand-wrapper {
              padding: 0 15px;

              .navbar-brand {
                img {
                  max-height: 100%;
                }
              }
            }

            .navbar {
              background: transparent;
              border-bottom: none !important;
              z-index: 100;
              .main-navbar-collapsable {
                ul {
                  &.mainmenu {
                    justify-content: flex-start;
                    /*display: -webkit-box;*/
                    display: flex;
                    height: auto;

                    & > li {
                      display: inline-block;
                      width: auto;
                      float:left !important;
                      /*position: relative;*/
                      -webkit-transition: all 300ms;
                      transition: all 300ms;
                      &.ddown {
                        transition: none;
                        border-right: 1px solid #ccc;

                        & > a {
                          border-top: 1px solid transparent;

                          &::after {
                            display: none;
                          }
                          &:hover {
                            border-top: 1px solid #ccc;
                          }
                        }
                      }

                      & > a {
                        padding: .75rem;
                      }

                      & > ul {
                        height: auto;
                        text-align: center;
                        width: auto;
                        max-width: 25em;
                        min-width: 100%;
                        word-wrap: break-word;
                        position: absolute;
                        z-index: 1;
                        margin-top: -1px !important;
                        &.collapse {
                          border: 1px solid rgba(0, 0, 0, 0.15);
                          column-count: 4;
                          box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);

                          li {
                            a {
                              color: #333 !important;
                              border: none;
                              box-shadow: none;
                              font-size: 12px;
                            }
                          }

                          .nav-dropdown-inner, .collapse-inner {
                            li {
                              width: 90%;
                              margin: auto;
                              border: 1px solid transparent;

                              &:hover {
                                border: 1px solid rgba(0, 142, 189, 0.5);
                                border-radius: 3px;
                                color: $basic-blue;
                              }
                            }
                          }
                          & > ul {
                            position: relative;
                          }
                        }
                      }

                     /* &:first-child {
                        margin-left: 15px;
                      }*/
                    }

                    li {
                      //border-top: 1px solid transparent;
                      a {
                        //&:hover {
                        //  border-top: 1px solid #ccc;
                        //}

                        &#home {
                          background: $basic-blue;
                          padding: 9px 12px;
                          border-top: 1px solid transparent;

                          &:hover {
                            i {
                              color: #666;
                            }
                          }

                          i {
                            color: $white;
                            font-size: 20px;
                            background: $basic-blue;
                          }
                        }
                      }
                    }
                  }

                  li {
                    color: $black;

                    a {
                      &:last-child {
                        border-right: 1px solid #ccc;
                        border-top: 1px solid transparent;
                      }
                    }

                    &:hover {
                      a {
                        background: $white;
                        color: $black;
                        position: relative;
                        z-index: 999999;
                      }
                    }

                    ul {
                      li {
                        line-height: 32px;
                      }
                    }
                  }
                }
              }
            }

            .cmp-search-box {
              margin-bottom: 20px;
              background: transparent;
              position: relative;

              /*.search-box-inner {*/
                /*.search-box-shadow-frame {*/
                  border-radius: 5px 0 0 5px;
                  box-shadow: none;

                  .search-submit {
                    color: $white;
                    border-radius: 0 5px 5px 0;
                    padding: 8px 11px;
                    background-color: $basic-blue;
                  }

                  .search-input {
                    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.075) inset;
                    border: 1px solid #ddd;
                    border-radius: 5px 0 0 5px;
                    font-size: 1rem;

                    &:hover {
                      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2) inset;
                    }

                    &:focus {
                      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1) inset;
                      background: rgba(0, 0, 0, 0.025);
                    }
                  }
               /* }*/
           /*   }*/
            }

          }

          .controls-list {
            & > li a:not(.btn) {
              color: $basic-black;
              background: transparent;
              padding: 0.5rem 0.8rem;
              display: inline-block;
               @media (max-width: 575px) {
                 padding: 0.5rem 0.6rem;
               }
            }

            li {
              border: 1px solid transparent;
              &.control-languages {
                border: none !important;
              }

              a {
                /*border: 1px solid transparent;*/
              }

              &:hover {
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
                background: transparent;
                border: 1px solid rgba(0, 0, 0, 0.15);
              }

              &.top-link-info {
                &:hover {
                  ul {
                    display: block;
                    padding: 0;

                    &.panel-a {
                      position: absolute;
                      z-index: 9999;
                      background: #fff;
                      border: 1px solid rgba(0, 0, 0, 0.15);
                      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.15);
                      padding: 10px;
                      padding-top: 0;
                      border-top: none;
                      //left: 211px;
                      margin-top: 2px;
                      margin-left: -1px;

                      li {
                        margin: 0;
                        font-size: 12px;
                        color: #555;
                        list-style-type: none;
                        border: none;

                        a {
                          width: 100%;
                          border: 1px solid transparent;
                          border-radius: 3px;
                          padding: 6px 15px;
                          margin-top: 6px;

                          &:hover {
                            border-color: rgba(0, 142, 189, .5);
                          }

                          &:before {
                            content: "";
                            position: absolute;
                            display: block;
                            left: 0;
                            right: 0;
                            top: -2px;
                            width: 74px;
                            height: 2px;
                            background: #fff;
                            z-index: 99999999;
                          }
                        }

                        &:hover {
                          border: none;
                          box-shadow: none;
                        }
                      }
                    }
                  }
                }
              }

              &.control-basket {
                .toggle-basket-preview {
                  & > * {
                    color: #ccc;
                  }
                }
              }

              &:nth-child(4) {
                &:hover {
                  border: none;
                  box-shadow: none;
                }
              }
            }
          }

          a {
            font-size: 13px;
            &[href="#login"],
            &[href="#registration"],
            &[data-testing="login-select"],
            &[data-testing="register-select"] {
              color: #555;
              padding: .65rem .8rem .7rem .8rem;
              line-height: 0.8rem;
              border: 1px solid transparent;

              &:hover {
                border: 1px solid rgba(0, 0, 0, .15);
                box-shadow: 0 1px 5px 0 rgba(0, 0, 0, .15);
                color: #555;
                background-color: $white;
              }

              /*i {
                margin-top: 9px;
                margin-bottom: 12px;
                margin-right: 2px;
              }*/
              @-moz-document url-prefix() {
                margin-top: 0;
              }
              /*border: 1px solid transparent;*/

              .dropdown {
                margin-top: 10px;

                a {
                  padding: .65rem .8rem;
                }
              }


              /*&:hover {
                border: 1px solid transparent;
              }*/
              /*.list-group {
                padding: 7px 0;
                border: 1px solid rgba(0, 0, 0, .15);
                box-shadow: 0 1px 5px rgba(0, 0, 0, .15);

                .small {
                  box-shadow: none;
                  border: 1px solid transparent;
                  margin: 0 10px 0;
                  margin-top: 2px;
                  padding: 0 15px;

                  &:hover {
                    border: 1px solid rgba(0, 142, 189, .5);
                    border-radius: 3px;
                    color: #333;
                  }

                  &:first-child {
                    margin-top: 0;
                  }
                }
              }*/
            }
            .badge {
              padding: 0;
            }
          }

          h4 {
            color: $secondary-blue;
            //margin-left: -15px;
          }

          .top-navi {
            flex-direction: row-reverse !important;
            width: 100%;
            .controls-list {
              display: inline-flex;
              .account-menu {
                z-index: 99999;
                padding: 0;
                min-width: 228px;
                border: none;
                margin-top: 1px;

                .list-group {
                  .list-group-item {
                    i {
                      font-family: FontAwesome;
                      margin-right: 5px;
                    }
                  }
                }
              }
            }
          }
        }
        .page-header {
          .cmp-country-settings {
            background: $white;
            color: #333333;
            margin-top: 6px;
            position: absolute;
            top: 31px;
            max-width: 1170px;
            width: 100%;
            z-index: 999;
            .language-settings {
              ul {
                display: flex;
                li {
                  @media (min-width: 992px) {
                    flex: 0 0 33%;
                    max-width: 33%;
                  }
                  a {
                    color: #333;
                    &:hover {
                      color: $white !important;
                    }
                  }
                  &.active {
                    a {
                      color: $white;
                    }
                  }
                }
              }
            }
          }
        }


        .main {
          /*max-width: 1200px;
          margin: auto;*/
          & > .container-max {
            background: $white;
          }

          .cmp-totals {
            div#ppButton {
              width: 50%;
              float: left;
              margin-top: 9px;
              margin-bottom: 5px;
            }
          }
        }

        .basket {
          .split-basket-height {
            .item-bundle {
              .text-primary {
                color: $muted !important;
                font-weight: 100;
              }

              .text-muted {
                font-weight: 100;
              }
            }
          }
        }

        .breadcrumb {
          padding: .5rem 20px;
        }


      /*.wrapper-right {*/
        .basket-preview {
          .basket-header {
            color: $muted;
          }

          .basket-preview-content {
            .split-basket-height {
              .item-bundle {
                .text-primary {
                  color: $muted !important;
                  font-weight: 100;
                }

                .text-muted {
                  font-weight: 100;
                }
              }
            }

            .cmp-totals {
              div#ppButton {
                width: 50%;
                float: left;
                margin-top: 9px;
                margin-bottom: 6px;
              }

              .amzPayButton {
                width: 50%;
                float: left;
              }
            }
            .basket-item a {
              padding: 0 !important;
            }
          }
        }
    /*  }*/

    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
      color: $secondary-blue;
    }
  }

  .btn {
    .fa {
      font-family: FontAwesome !important;
    }
  }
}

/* */

html {

  .page-content {
    background: $white;
  }

  div#ts_review_sticker {
    margin-top: 20px;
  }

  .pipe {
    display: none;
  }

  .main-navbar-collapsable ul.mainmenu > li.ddown > a::after {
    background: $white;

  }

  nav.small.hidden-sm-down {
    max-width: 1170px;
    padding: 5px;
    background: $white;
    margin: auto;
  }

  .parallax-img-container {
    .parallax-img-container-inner[style^="background-image"] {
      height: 280px;
      background-attachment: scroll;

      .parallax-text-container {
        top: 10px;

        .h2.pt-4.category-title {
          font-size: 20px !important;
          padding: 10px;
          color: $secondary-blue !important;
          box-shadow: none;
          margin-bottom: 20px;
        }
      }
    }
  }

   header .container-max > .row {
    flex-direction: row;
  }

  .container-max.feature-a {
    flex-direction: row-reverse;
    display: flex;
  }

  .single .nav-tabs .nav-item .nav-link:hover {
    background: $white;
  }

  .social-list {
    .btn {
      height: auto;
      line-height: 15px;
      padding: 2px 9px 2px 5px;
      font-size: .9em;
      opacity: .8;
      cursor: default;
      margin-bottom: 8px;
    }

    .btn-default {
      border-color: rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.15) rgba(0, 0, 0, 0.2);
      color: #444;
      text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
    }
  }


  .shariff {
    margin-top: 25px;
    margin-bottom: 15px;
  }

  .single .nav-tabs .nav-item + .nav-item {
    margin-left: 0;
  }

  .Cfourhidden {
    display: none;
  }

  .sidebar-categories ul li {
    .active {
      ul li {
        a {
          padding-left: 2.5rem !important;
        }
        ul li a {
          padding-left: 1.5rem !important;
        }
      }
      .toggle {
        margin-left: -11px !important;
      }
    }

    ul li {
      & > a {
        padding-left: 2.5rem !important;
      }
      .toggle {
        margin-left: -11px !important;
      }
      ul li > a {
        padding-left: 3.5rem !important;
      }
    }
  }

  .sidebar-categories ul li ul {
    display: block;
  }

  .navbar-toggler {
    font-size: 2.25rem;
    padding: .75rem 1rem;
    color: #333;
    border: 1px solid #ddd;
    z-index: 9;
    position: absolute;
    right: 2rem;
    top: 5rem;
    @media (max-width: 575px) {
      right: 1rem;
    }
  }

  .mobile-navigation {
    .fa, .far, .fas {
      font-family: FontAwesome;
    }
  }

  .alert {
    width: auto;
  }

  div#ts_product_widget {
    margin: 15px 0 5px;
  }

  #tsbadge4_db8d3657bdbe440c985ae127463eaad4 {
    top: 50%;
    z-index: 1079 !important;
  }

  .extended-buttons {
    display: flex;
    flex-direction: row-reverse;
    padding: 5px 15px;

    & > div {
      margin-left: 10px;
    }
  }
}
.container-max.login,
.page-content.basket, .page-content.checkout, .page-content.contact, .page-content.myaccount {
  margin-top: 0px;
  padding-bottom: 30px;
}
.container-max.login {
  padding-top: 30px;
}

.add-to-basket-container {
  .qty-box {
    height: 41px;
  }
}

.cmp-product-thumb {
  border: 1px solid #ddd;
}

.cmp-contact {
  iframe {
    width: 100%;
  }
}

.modal-body {
  div#amzLoginButton_0 img:last-child {
    display: none;
  }
  div#amzLoginButton_1 img:last-child {
    display: none;
  }
}

.page-home .main {
  max-width: 1200px;
  margin: auto;
  background: $white;
  padding: 0 15px;
}

#page-header {
  & > .container-max {
    background: $white;
  }
}
