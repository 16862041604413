.footer {
  background: transparent;
    @media (max-width: 767px) {
      & > .row > .col {
        padding: 0;
      }
    }
    @media (min-width: 768px) {
      & > .row > .col {
        background: #fff;
      }
    }
  .widget {
    background: $white;

    &.widget-grid {
      margin-left: 0;
      margin-right: 0;
      background: $white;
    }

    &.widget-link-list {
      padding: 15px;
    }
  }
  .copyright {
    margin-top: -45px;
    background: #fff;
    margin-left: -21px;
    margin-right: -21px;
    font-size: 12px;
    padding-left: 27px;
    padding-bottom: 15px;
    padding-top: 14px;
  }
  .h4 {
    color: #333;
    font-size: 14px;
    font-weight: 900;
    margin-bottom: 15px;
  }
   @media (max-width: 575px) {
     .btn.btn-secondary {
       background-color: #4D4F4D;
       cursor: pointer;
       width: 100%;
       border-color: #4D4F4D;
       margin-left: -15px;
       position: absolute;
       z-index: 9999;
       font-size: 1rem;
       padding: 1rem !important;
     }
   }
}
