html {
  body {

      .main {
        .categoriegrid {
          background: $white;

          .cmp-product-thumb--grid, .cmp-product-thumb {
            border: 1px solid #ddd;

            .thumb-content {
              .thumb-title {
                color: $basic-blue;
                font-size: 14px;
                line-height: 26px;
                min-height: 105px;
              }

              .thumb-meta {
                .prices {
                  display: block;

                  .price {
                    color: #333;
                    text-align: center;
                  }
                }
                /*.category-unit-price {
                  display: none;
                }*/
              }
              .category-unit-price {
                font-size: .81rem;
              }

              .vat {
                text-align: center;
                display: block;
                margin-top: 2px;
              }
              .availability {
                font-size: 10px;
                padding: 0.15rem;
              }
            }
          }

          .badge:empty, .tag:empty {
            display: block;
            float: right;
            margin-top: -22px;
            width: 15px;
            height: 15px;
          }

          .list-controls {
            padding-top: 9px;

            .cmp-paginator {
              margin-right: 5px;
            }
          }

          #CfourListingSwitch {
            margin-top: 8px;
            position: relative;
            form {
              display: inline-block;
            }
            .switchButton {
              color: $black;
              font-size: 22px;
              background: $white;
              border: 1px solid #dee2e6;
              &:hover, &.active {
                color: $basic-blue;
                background: $white;
                border-color: rgba(0, 142, 189, 0.5);
              }
            }
          }

          #CfourDetailedListing {
            .cmp-product-thumb {
              .articlenumber {
                color: #333;
              }
              .price {
                color: #333;
                text-align: left;
              }
              .thumb-content {
                background: transparent;
                .thumb-title {
                  min-height: auto !important;
                }
              }
            }
            .category-list-view-port {
              display: block;
            }
          }

          #CfourTileListing {
            width: 100%;
            .cmp-product-thumb {
              border: 1px solid #ddd;
              .category-list-view-port {
                display: block;
                .col-12 {
                  padding: 0;
                  .quantity-input-container {
                    .qty-box .qty-input {
                      width: 40px;
                    }
                  }
                 /* .btn {
                    i {
                      display: none;
                    }
                  }*/

                }
              }
            }
          }
          .CfourListingButtons {
            width: 60%;
            .col-12 {
              padding: 0;
            }
          }
        }

        .sidebar-categories {
          .filter-properties {
            margin-bottom: 20px;
            margin-top: 23px;
            .filter-wrapper {
              .card-columns {
                column-count: 1;
              .card {
                margin-bottom: 10px;

                .h3 {
                  font-size: 16px;
                  margin-left: 15px;
                  margin-top: 15px;
                  color: $basic-blue;
                }

                .form-check {
                  padding-left: 1.75rem;
                }
              }
            }
            }
          }
          .category-title {
            color: $secondary-blue;
            background: transparent !important;
            font-weight: 500;
            padding-top: 0;
            padding-left: 15px;
            text-transform: none;
            font-size: 21px;
            margin-top: -2px;
          }
          ul {
            li {
              &.active {
                & > a {
                  background: transparent;
                }
              }
              a {
                padding-top: .75rem;
                padding-bottom: .5rem;
                color: #333;
                &.toggle {
                  position: absolute;
                  //margin-left: -11px;
                  margin-left: 11px;
                  top: 0;
                  background: transparent !important;
                  height: 35px;
                  width: 10px;
                  display: block;
                  vertical-align: middle;
                  &:hover {
                    color: $basic-blue !important;
                  }
                  .fa {
                    transition: all 0.1s linear 0s;
                  }
                  &[aria-expanded="true"] .fa {
                    transform: rotate(90deg);
                  }
                }
                &:not(.toggle) {
                  border: 1px solid transparent;
                  &:hover {
                    background: transparent;
                    color: $basic-blue;
                    border: 1px solid rgba(0, 142, 189, 0.5);
                    border-radius: 3px;
                  }
                }
              }
              &:hover {
                & > a.toggle {
                  color: $basic-blue;
                }
              }
            }
          }
          & > ul {
            & > .nav-item {
              &.active {
                display: block;
              }
            }
          }
          .nav-item {
            position: relative;
          }
        }
      }

  }
}

.parallax-img-container {
  max-width: 1200px;
  margin: auto;
}


.cmp-product-thumb {
  .badge:empty, .tag:empty {
    display: block;
    float: right;
    margin-top: -22px;
    width: 15px;
    height: 15px;
    font-size: 10px;
    padding: 0.15rem;
  }
}
