

.social-switch.on {
  background-color: #3b0;
  padding-left: 11px;
}
.social-switch {
  height: 12px;
  width: 23px;
  border-radius: 6px;
  text-indent: -10000em;
  float: left;
  margin-top: 5px;
  margin-right: 6px;
  padding: 0;
  padding-left: 0px;
  overflow: hidden;
  background-image: none;
  border: 1px solid #aaa;
  -webkit-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3) inset;
  -moz-box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3) inset;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.3) inset;
}

.social-list li > div {
  display: inline-block;
}

.social-switch > span {
  height: 10px;
  width: 10px;
  display: block;
  background-color: #eee;
  border-radius: 50%;
  box-shadow: 0 0 3px #000;
}

.social-switch.off {
  cursor: pointer;
  background-color: #ccc;
}

ul.social-list {
  list-style: none;
  padding-left: 0;
  display:flex;
}

ul.social-list li {
  width: 50%;
}

.back-to-top {
  margin: 0;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  width: 40px;
  height: 40px;
  z-index: 100;
  display: none;
  text-decoration: none;
  opacity: 0.5;
  -webkit-transition: opacity 300ms;
  transition: opacity 300ms;
  border: 1px solid #282d2f !important;
  background: #4D4F4D !important;
  border-radius: 0.1rem;
  cursor: pointer;
  text-align: center;
  i {
    font-size: 24px;
    color: #f7f7f9;
  }
  &:hover {
    opacity: 1;
  }
}


.owl-carousel {
  overflow: hidden;
  .owl-stage-outer {
    overflow: visible;
  }
}

/*  */

